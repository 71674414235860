<template>
  <div class="d-flex justify-content-center" id="fq-game-fail">
    <img src="/assets/img/fqgame/fail.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'FQGameFail'
}
</script>
